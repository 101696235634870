// === CSS
import '../../sass/components/age-disclaimer-popup.scss'

import JsCookie from 'js-cookie'
import Modal from 'bootstrap/js/src/modal'

document.addEventListener('alpha.loaded', () => {
  const modalPopupEl = document.querySelector('.age-disclaimer-popup')
  if (modalPopupEl) {
    const modalInstance = Modal.getOrCreateInstance(modalPopupEl)
    const key = `modal-${modalPopupEl.id}-shown`
    const validateAgeBtnEl = document.querySelector('.validate-age-btn')
    if (validateAgeBtnEl) {
      if (!JsCookie.get(key)) {
        modalInstance.show()

        validateAgeBtnEl.addEventListener('click', (e) => {
          e.preventDefault()

          modalInstance.hide()
          JsCookie.set(key, true)
        })
      }
    }
  }
})
